$(document).ready(function(){
	
	
	$('.panel-collapse').on('hide.bs.collapse', function () {
		
		$("audio").each(function(){
		    this.pause(); // Stop playing
		    if (this.currentTime > 0) {
		    	this.currentTime = 0; // Reset time
		    }
		}); 
		
		$('.audio-player').each(function() {
			$('.fa-volume-up',this).show();
			$('.fa-pause',this).hide();
		});
		
	})	


	$('.panel-collapse').on('show.bs.collapse', function () {
		
		var selected_header = '#heading' + $(this).data('id');
		
		$(selected_header + " .fa-chevron-circle-up").css('display', 'inline-block');
		$(selected_header + " .fa-chevron-circle-down").css('display', 'none');
		
	})
	
	$('.panel-collapse').on('shown.bs.collapse', function () {
		
		var selected_header = '#heading' + $(this).data('id');
		$.scrollTo(selected_header, 500);
		
	})
	
	$('.panel-collapse').on('hide.bs.collapse', function () {
	
		var selected_header = '#heading' + $(this).data('id');
		
		$(selected_header + " .fa-chevron-circle-up").css('display', 'none');
		$(selected_header + " .fa-chevron-circle-down").css('display', 'inline-block');
	
	})
	
	$('.audio-player').each(function() {
		var audio_container = "#" + $(this).attr('id');	
		
		//hide pause at first
		$('.fa-pause',this).hide();
		
		$('.fa-volume-up', this).click(function() {			
			$(audio_container + " audio").trigger('play');
			$(this).hide();
			$(audio_container + " .fa-pause").show();
			
		})
		
		$('.fa-pause', this).click(function() {			
			$(audio_container + " audio").trigger('pause');
			$(this).hide();
			$(audio_container + " .fa-volume-up").show();
		})

	})
	
	$('.section-nav .thumbnail').click(function() {	
		
		$('#footer-nav').show();
		
		$('.section').each(function() {
			$(this).hide();
		})
		
		var to_show = "#" + $(this).data('show');
		$(to_show).show();
		$.scrollTo(to_show, 500);
	})
	
});